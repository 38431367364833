import { Timestamp } from "@bufbuild/protobuf";
import { createRouterTransport } from "@connectrpc/connect";
import { AccountService } from "schema/gen/es/chiikipoint/companyconsole/v2/account_service_connect";
import {
  type GetTenantRequest,
  GetTenantResponse,
  type LoginRequest,
  LoginResponse,
} from "schema/gen/es/chiikipoint/companyconsole/v2/account_service_pb";
import { CompanyService } from "schema/gen/es/chiikipoint/companyconsole/v2/service_connect";
import {
  type GetMyCompanyRequest,
  GetMyCompanyResponse,
  type GetRedemptionsRequest,
  GetRedemptionsResponse,
  type GetTransactionsRequest,
  GetTransactionsResponse,
  type RefundRequest,
  RefundResponse,
  type UpdateMyCompanyImageRequest,
  UpdateMyCompanyImageResponse,
} from "schema/gen/es/chiikipoint/companyconsole/v2/service_pb";
import {
  Tenant,
  TimePaginationResponse,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import {
  CompanyWithStores,
  Redemption,
  TransactionGroup,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";

export const mockGetTenant = async (
  _req: GetTenantRequest,
): Promise<GetTenantResponse> => {
  const tenant = new Tenant({
    id: "550e8400-e29b-41d4-a716-446655440000",
    name: "デモ地域ポイント",
    slug: "demo",
    serviceId: "b43596db-9481-432f-ae7f-d4a89de9af7c",
    contact: "0120-1234-5678",
    iconUrl: "/misc/icons/demo.png",
  });
  return new GetTenantResponse({
    tenant,
  });
};

export const mockLogin = async (_req: LoginRequest): Promise<LoginResponse> => {
  return new LoginResponse({
    token: "mock-token",
  });
};

export const mockGetMyCompany = async (
  _req: GetMyCompanyRequest,
): Promise<GetMyCompanyResponse> => {
  const companyWithStores = new CompanyWithStores({
    company: {
      id: "67e55044-10b1-426f-9247-bb680e5fe0c8",
      name: "みやポマート",
      ownerName: "日本橋 太郎",
      address: "宮城県仙台市青葉区一番町１丁目３−２",
      contact: "022-123-4567",
      iconUrl: "/misc/default-store-icon.png",
      bankName: "日本橋銀行",
      bankBranchName: "日本橋支店",
      bankAccountType: "普通",
      bankAccountNumber: "1234567",
      bankAccountHolderName: "日本橋 太郎",
    },
    stores: [
      {
        id: "RYks43cB",
        name: "みやぎ生協",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "DJuKFAPf",
        name: "丸森観光物産",
        fullAddress: "宮城県伊具郡丸森町字下滝12",
        city: "宮城県伊具郡丸森町",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "LaX36SYr",
        name: "テスト店舗3",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
      {
        id: "Mm2WXq8P",
        name: "テスト店舗4とても長い店舗名のテストtesting",
        fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
        city: "宮城県仙台市青葉区",
        location: {
          latitude: 38.25783587179756,
          longitude: 140.87284595831724,
        },
        category: "コンビニ",
        iconUrl: "/misc/storefront.png",
      },
    ],
  });
  return new GetMyCompanyResponse({
    companyWithStores,
  });
};

export const mockUpdateMyCompanyImage = async (
  _req: UpdateMyCompanyImageRequest,
): Promise<UpdateMyCompanyImageResponse> => {
  return new UpdateMyCompanyImageResponse();
};

export const mockGetTransactions = async (
  _req: GetTransactionsRequest,
): Promise<GetTransactionsResponse> => {
  const transactionGroups: TransactionGroup[] = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(
    (i) =>
      new TransactionGroup({
        id: `9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6${i}`,
        transactionDetails: [
          {
            transaction: {
              id: "df880a2a-0101-45a7-a1cc-e2f4f7c1e4b7",
              groupId: "9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d",
              userId: "6ca9d764-5d2f-405a-b0a2-656eeac151ac",
              type: 2, // PAYMENT
              balanceAddId: "0191f997-35db-7d43-a6a0-652120513df6",
              amount: BigInt(-100),
              fundSourceId: "0191f997-b5fc-7732-9303-a06f08183c09",
              reason: "支払い",
              createdAt: {
                seconds: BigInt(1739634210),
                nanos: 0,
              },
            },
            store: {
              id: "8f0d2d9e-c9c9-4b4a-9f9c-0c5c9c9c9c9c",
              name: "みやポマート",
              fullAddress: "宮城県仙台市青葉区一番町１丁目３−２",
              city: "宮城県仙台市青葉区",
              location: {
                latitude: 38.25783587179756,
                longitude: 140.87284595831724,
              },
              category: "コンビニ",
              iconUrl: "/misc/storefront.png",
            },
          },
        ],
      }),
  );
  return new GetTransactionsResponse({
    transactionGroups,
    pagination: new TimePaginationResponse({
      nextOffset: Timestamp.fromDate(
        new Date(Date.now() + 1000 * 60 * 60 * 24),
      ),
    }),
  });
};

export const mockGetRedemptions = async (
  _req: GetRedemptionsRequest,
): Promise<GetRedemptionsResponse> => {
  const now = Timestamp.fromDate(new Date());
  const redemptions: Redemption[] = [
    new Redemption({
      periodStart: now,
      periodEnd: now,
      transactions: [],
    }),
  ];
  return new GetRedemptionsResponse({
    redemptions,
  });
};

export const mockRefund = async (
  _req: RefundRequest,
): Promise<RefundResponse> => {
  const now = Timestamp.fromDate(new Date());
  const transactions: Redemption[] = [
    new Redemption({
      periodStart: now,
      periodEnd: now,
      transactions: [],
    }),
  ];
  return new RefundResponse({
    transactions,
  });
};

export const accountServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(AccountService, {
      getTenant: mockGetTenant,
      login: mockLogin,
    });
  },
);

export const companyServiceMockTransport = createRouterTransport(
  ({ service }) => {
    service(CompanyService, {
      getMyCompany: mockGetMyCompany,
      updateMyCompanyImage: mockUpdateMyCompanyImage,
      getTransactions: mockGetTransactions,
      getRedemptions: mockGetRedemptions,
      refund: mockRefund,
    });
  },
);
