import * as Sentry from "@sentry/react";
import {
  CatchBoundary,
  type ErrorComponentProps,
} from "@tanstack/react-router";
import { format } from "date-fns";
import { useState } from "react";
import { css } from "../../styled-system/css";

interface ErrorWithSentryEventId extends Error {
  sentryEventId?: string;
}

type Props = {
  children: React.ReactNode;
};

function ErrorPage({ error }: ErrorComponentProps) {
  const [time] = useState<number>(Date.now());
  const sentryEventId = (error as ErrorWithSentryEventId).sentryEventId;

  return (
    <div
      className={css({
        h: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bg: "background.primary",
      })}
    >
      <div
        className={css({
          bg: "white",
          p: "8",
          borderRadius: "lg",
          maxW: "md",
          w: "full",
          textAlign: "center",
        })}
      >
        <h1
          className={css({
            fontSize: "xl",
            fontWeight: "bold",
            mb: "4",
          })}
        >
          エラーが発生しました
        </h1>
        <p
          className={css({
            color: "text.secondary",
            mb: "6",
          })}
        >
          {format(time, "yyyy/MM/dd HH:mm:ss")}
        </p>
        {sentryEventId && (
          <p
            className={css({
              color: "text.secondary",
              mb: "6",
              fontSize: "sm",
            })}
          >
            エラーID: {sentryEventId}
          </p>
        )}
        <div
          className={css({
            display: "flex",
            gap: "4",
            justifyContent: "center",
          })}
        >
          <button
            type="button"
            onClick={() => window.location.reload()}
            className={css({
              px: "6",
              py: "2",
              bg: "surface.accentPrimary",
              color: "white",
              borderRadius: "md",
              fontWeight: "bold",
            })}
          >
            再読み込み
          </button>
          <button
            type="button"
            onClick={() => {
              localStorage.removeItem("token");
              window.location.href = "/login/company";
            }}
            className={css({
              px: "6",
              py: "2",
              border: "1px solid",
              borderColor: "border.secondary",
              borderRadius: "md",
              fontWeight: "bold",
            })}
          >
            ログイン画面に戻る
          </button>
        </div>
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }: Props) {
  return (
    <CatchBoundary
      getResetKey={() => "reset"}
      onCatch={(error) => {
        console.error("Caught error:", error);

        const eventId = Sentry.captureException(error);
        (error as ErrorWithSentryEventId).sentryEventId = eventId;
      }}
      errorComponent={ErrorPage}
    >
      {children}
    </CatchBoundary>
  );
}
