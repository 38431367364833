import { Link, createFileRoute, redirect } from "@tanstack/react-router";
import { QRCodeSVG } from "qrcode.react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { z } from "zod";
import { css } from "../../../../styled-system/css";
import psSymbol from "../../../assets/ps-symbol.png";
import { getQRCodeURL } from "../../../libs/ps";

const searchSchema = z.object({
  qrId: z.string().optional(),
});

type Search = z.infer<typeof searchSchema>;

const CUSHION_SERVICE_ID = import.meta.env.VITE_CUSHION_SERVICE_ID;

function generateUrl(storeId: string, t?: string) {
  const tag = t && t.length > 0 ? `?t=${t}` : "";
  return getQRCodeURL(CUSHION_SERVICE_ID, `/s/${storeId}${tag}`);
}

export const Route = createFileRoute("/company/stores/$storeId/qrcode")({
  validateSearch: (search: Search) => searchSchema.parse(search),
  loaderDeps: ({ search: { qrId } }: { search: Search }) => ({
    qrId,
  }),
  loader: async ({
    context: { stores },
    deps: { qrId },
    params: { storeId },
  }) => {
    const store = stores.find((store) => store.id === storeId);
    if (!store) {
      throw redirect({ to: "/company/stores" });
    }

    const qrCodeUrl = generateUrl(store.id, qrId);

    return { store, qrId, qrCodeUrl };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { store, qrCodeUrl, qrId } = Route.useLoaderData();
  const contentRef = useRef<HTMLDivElement>(null);
  console.log(qrCodeUrl);

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: "QRコード",
    pageStyle: `
    @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
      @page {
        size: A4;
        margin: 0;
      }
      @media print {
        html, body {
          height: 100vh;
          margin: 0 !important;
          padding: 0 !important;
          overflow: hidden;
          -webkit-print-color-adjust: exact;
          font-family: "Noto Sans JP", sans-serif;
          font-optical-sizing: auto;
        }
      }
    `,
  } as const);

  return (
    <div className={css({ p: "4" })}>
      <div className={css({ mb: "4" })}>
        <Link to="/company/stores">店舗一覧に戻る</Link>
      </div>

      <div
        ref={contentRef}
        className={css({
          p: "8",
          bg: "white",
          "@media print": {
            w: "100%",
            h: "100%",
            p: "0",
          },
        })}
      >
        <style type="text/css" media="print">
          {`
            @page {
              size: A4;
              margin: 0;
            }
          `}
        </style>

        <div
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            h: "full",
          })}
        >
          <div
            style={{ width: "6cm", height: "6cm" }}
            className={css({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              border: "1px dashed",
              borderColor: "border.secondary",
              p: "6mm",
              boxSizing: "border-box",
              position: "relative",
            })}
          >
            <div
              className={css({
                transform: "rotate(-90deg)",
                fontSize: "2.6mm",
                whiteSpace: "nowrap",
                h: "4mm",
                w: "full",
                position: "absolute",
                top: "2.8cm",
                left: "3.2cm",
                textAlign: "center",
                bottom: "50%",
                maxW: "52mm",
                overflow: "hidden",
                textOverflow: "ellipsis",
              })}
            >
              {qrId}
            </div>
            <div
              className={css({
                fontSize: "2.6mm",
                h: "4mm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                position: "absolute",
                bottom: "0",
                left: 0,
                right: 0,
                textAlign: "center",
              })}
            >
              {store.name}
            </div>
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              })}
            >
              <QRCodeSVG
                value={qrCodeUrl}
                size={226}
                level="H"
                className={css({
                  w: "full",
                  h: "full",
                })}
              />

              <div
                className={css({
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "1",
                  p: "1mm",
                  bg: "white",
                  borderRadius: "4px",
                })}
              >
                <img src={psSymbol} alt="PS" className={css({ h: "8mm" })} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={css({
          mb: "4",
          display: "flex",
          justifyContent: "center",
          p: "4",
        })}
      >
        <button
          type="button"
          onClick={() => handlePrint()}
          className={css({
            px: "4",
            py: "2",
            bg: "surface.accentPrimary",
            color: "text.inverse",
            borderRadius: "md",
            _hover: { bg: "action.accentPrimaryHover" },
          })}
        >
          印刷する
        </button>
      </div>
    </div>
  );
}
