import { Outlet, createRootRoute } from "@tanstack/react-router";
import * as React from "react";
import { Toaster } from "react-hot-toast";
import { ErrorBoundary } from "../components/error-boundary";

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <ErrorBoundary>
      <React.Fragment>
        <Outlet />
        <Toaster />
      </React.Fragment>
    </ErrorBoundary>
  );
}
