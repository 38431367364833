// client.ts

import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AccountService } from "schema/gen/es/chiikipoint/companyconsole/v2/account_service_connect.js";
import { CompanyService } from "schema/gen/es/chiikipoint/companyconsole/v2/service_connect";
import {
  accountServiceMockTransport,
  companyServiceMockTransport,
} from "./mock";
import { isMockEnv } from "./utils";

const accountTransport = createConnectTransport({
  baseUrl: "/",
});

const createCompanyTransport = ({ token }: { token: string }) =>
  createConnectTransport({
    baseUrl: "/",
    interceptors: [
      (next) => async (req) => {
        req.header.set("Authorization", `Bearer ${token}`);
        return next(req);
      },
    ],
  });

export const createAccountClient = () => {
  const mock = isMockEnv();
  return createPromiseClient(
    AccountService,
    mock ? accountServiceMockTransport : accountTransport,
  );
};

type CreateCompanyClientParams = {
  token: string;
  mock?: boolean;
};

export type CompanyClient = ReturnType<
  typeof createPromiseClient<typeof CompanyService>
>;

export const createCompanyClient = ({ token }: CreateCompanyClientParams) => {
  const mock = isMockEnv();
  return createPromiseClient(
    CompanyService,
    mock ? companyServiceMockTransport : createCompanyTransport({ token }),
  );
};
