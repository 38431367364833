import {
  Link,
  Outlet,
  createFileRoute,
  redirect,
  useNavigate,
} from "@tanstack/react-router";
import { decodeJwt } from "jose";
import { useState } from "react";
import type {
  Company,
  Store,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import Close from "~icons/material-symbols/close";
import CreditCard from "~icons/material-symbols/credit-card-outline";
import Description from "~icons/material-symbols/description-outline";
import Help from "~icons/material-symbols/help-outline";
import Logout from "~icons/material-symbols/logout";
import Menu from "~icons/material-symbols/menu";
import StoreFront from "~icons/material-symbols/storefront-outline";
import SyncAlt from "~icons/material-symbols/sync-alt";
import { type Styles, css } from "../../styled-system/css";
import logo from "../assets/logo.png";
import psImg from "../assets/ps-symbol.png";
import { type CompanyClient, createCompanyClient } from "../libs/client";
import { getTenantSlug } from "../libs/utils";

type LoaderData = {
  client: CompanyClient;
  company: Company;
  stores: Store[];
};

export const Route = createFileRoute("/company")({
  beforeLoad: async (): Promise<LoaderData> => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw redirect({ to: "/login/company" });
    }

    try {
      const payload = decodeJwt(token);

      if (payload.exp && Date.now() >= payload.exp * 1000) {
        localStorage.removeItem("token");
        throw redirect({ to: "/login/company" });
      }
    } catch (e) {
      localStorage.removeItem("token");
      throw redirect({ to: "/login/company" });
    }

    const client = createCompanyClient({ token });
    const myCompany = await client.getMyCompany({});
    if (!myCompany.companyWithStores) {
      throw new Error("myCompany is not found");
    }
    const { company, stores } = myCompany.companyWithStores;
    if (!company) {
      throw new Error("company is not found");
    }
    return {
      client,
      company,
      stores,
    };
  },

  component: RouteComponent,
});

const linkStyle: Styles = {
  px: "16px",
  py: "12px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  "&.active": {
    color: "#3E3EF8EE",
    fontWeight: "bold",
    bg: "rgba(62, 62, 248, 0.08)",
  },
};

function TenantLabel() {
  let name = "";
  let bgColor = "";
  let textColor = "";
  const tenantSlug = getTenantSlug();

  if (tenantSlug === "miyagi") {
    name = "みやぎポイント";
    bgColor = "#355077";
    textColor = "white";
  }
  if (tenantSlug === "marumori") {
    name = "まるもり地域ポイント";
    bgColor = "#FDF1D1";
    textColor = "text.primary";
  }
  if (tenantSlug === "demo") {
    name = "デモ地域ポイント";
    bgColor = "#e6763c";
    textColor = "white";
  }

  return (
    <div
      className={css({
        px: "2",
        py: "4px",
        borderRadius: "sm",
        fontSize: "12px",
        fontWeight: "bold",
      })}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      {name}
    </div>
  );
}

function Layout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate({ to: "/login/company" });
  };

  return (
    <>
      <header
        className={css({
          h: "52px",
          bg: "white",
          w: "full",
          px: "4",
          py: "2",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "border.secondary",
        })}
      >
        <div
          className={css({
            display: { base: "none", md: "flex" },
            alignItems: "center",
          })}
        >
          <img
            src={logo}
            alt="logo"
            className={css({
              h: "15px",
            })}
          />
          <div
            className={css({
              color: "text.secondary",
              ml: "2",
            })}
          >
            地域ポイント
          </div>

          <div
            className={css({
              color: "text.secondary",
              ml: "4",
              borderLeft: "1px solid",
              borderColor: "border.secondary",
              borderRight: "1px solid",
              px: "4",
            })}
          >
            事業者コンソール
          </div>
        </div>

        <div
          className={css({
            display: "flex",
            alignItems: "center",
            justifyContent: { md: "flex-start" },
            flex: 1,
          })}
        >
          <img
            src={psImg}
            alt="ps"
            className={css({
              h: "26px",
              mr: "2",
              display: { base: "block", md: "none" },
            })}
          />
          <div className={css({ ml: { base: 0, md: "4" } })}>
            <TenantLabel />
          </div>
          <button
            type="button"
            className={css({
              display: { base: "flex", md: "none" },
              ml: "auto",
              alignItems: "center",
              justifyContent: "center",
              p: "2",
              cursor: "pointer",
            })}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <Close /> : <Menu />}
          </button>
        </div>
      </header>
      <div
        className={css({
          display: "flex",
          h: "calc(100% - 52px)",
        })}
      >
        <aside
          className={css({
            w: { base: "240px", md: "240px" },
            bg: "white",
            h: "full",
            borderRight: "1px solid",
            borderColor: "border.secondary",
            display: "flex",
            flexDirection: "column",
            position: { base: "fixed", md: "static" },
            left: { base: isMenuOpen ? "0" : "-240px", md: "0" },
            top: "52px",
            transition: "left 0.3s",
            zIndex: "10",
          })}
        >
          <div>
            <Link to="/company/dashboard" className={css(linkStyle)}>
              <Description />
              <span>登録情報</span>
            </Link>
            <Link to="/company/stores" className={css(linkStyle)}>
              <StoreFront />
              <span>店舗一覧</span>
            </Link>
            <Link to="/company/transactions" className={css(linkStyle)}>
              <SyncAlt />
              <span>取引履歴</span>
            </Link>
            <Link to="/company/redemptions" className={css(linkStyle)}>
              <CreditCard />
              <span>振込履歴</span>
            </Link>

            <Link to="/company/contact" className={css(linkStyle)}>
              <Help />
              <span>お問い合わせ</span>
            </Link>
          </div>

          <button
            type="button"
            className={css({
              mt: "auto",
              w: "full",
              px: "4",
              py: "4",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              textAlign: "left",
              cursor: "pointer",
              _hover: {
                bg: "rgba(0, 0, 0, 0.05)",
              },
            })}
            onClick={handleLogout}
          >
            <Logout />
            <span>ログアウト</span>
          </button>
        </aside>

        {isMenuOpen && (
          <button
            type="button"
            className={css({
              position: "fixed",
              top: "52px",
              left: 0,
              right: 0,
              bottom: 0,
              bg: "rgba(0, 0, 0, 0.5)",
              zIndex: "5",
              display: { base: "block", md: "none" },
            })}
            onClick={() => setIsMenuOpen(false)}
          />
        )}

        <main
          className={css({
            bg: "background.background",
            flex: "1",
            minHeight: { base: "calc(100vh - 52px)", md: "auto" },
            paddingBottom: { base: "40px", md: "0" },
          })}
        >
          {children}
        </main>
      </div>
    </>
  );
}

function RouteComponent() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
