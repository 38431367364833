import { Timestamp } from "@bufbuild/protobuf";
import { createFileRoute } from "@tanstack/react-router";
import { startOfDay, startOfMonth, subDays, subHours } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type TimePaginationResponse,
  type TransactionGroup,
  User_Gender,
} from "schema/gen/es/chiikipoint/model/v2/model_pb";
import { css } from "../../../styled-system/css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { ja } from "date-fns/locale";
import {
  DateRangePicker,
  type Range,
  type RangeKeyDict,
} from "react-date-range";
import toast from "react-hot-toast";
import Id from "../../components/id";

type DatePickerProps = {
  onSubmit: (range: Range) => void;
  onCancel: () => void;
};

function DatePicker({ onSubmit, onCancel }: DatePickerProps) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges: RangeKeyDict) => {
    if (ranges.selection.startDate && ranges.selection.endDate) {
      setDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: "selection",
      });
    }
  };

  const handleSubmit = () => {
    onSubmit(dateRange);
  };

  return (
    <div>
      <div
        className={css({
          border: "1px solid",
          borderColor: "border.secondary",
          borderRadius: "8px",
        })}
      >
        <DateRangePicker
          locale={ja}
          ranges={[dateRange]}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          inputRanges={[]}
          staticRanges={[
            {
              label: "過去1時間",
              range: () => ({
                startDate: subHours(new Date(), 1),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今日",
              range: () => ({
                startDate: startOfDay(new Date()),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "過去2日",
              range: () => ({
                startDate: subDays(new Date(), 1),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "過去3日",
              range: () => ({
                startDate: subDays(new Date(), 2),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今週",
              range: () => ({
                startDate: subDays(new Date(), 7),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
            {
              label: "今月",
              range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: new Date(),
                key: "selection",
              }),
              isSelected: () => false,
            },
          ]}
        />
      </div>
      <div
        className={css({
          display: "flex",
          gap: "8px",
          p: "8px",
        })}
      >
        <button
          type="button"
          className={css({
            border: "1px solid",
            borderColor: "border.secondary",
            borderRadius: "8px",
            px: "12px",
            py: "4px",
            cursor: "pointer",
            ml: "auto",
          })}
          onClick={onCancel}
        >
          キャンセル
        </button>
        <button
          type="button"
          className={css({
            bg: "surface.accentPrimary",
            color: "text.inverse",
            border: "none",
            borderRadius: "8px",
            px: "30px",
            py: "4px",
            cursor: "pointer",
          })}
          onClick={handleSubmit}
        >
          確定
        </button>
      </div>
    </div>
  );
}

function genderText(gender: User_Gender) {
  switch (gender) {
    case User_Gender.MALE:
      return "男性";
    case User_Gender.FEMALE:
      return "女性";
    case User_Gender.UNSPECIFIED:
      return "-";
    default:
      return "未定義";
  }
}

export const Route = createFileRoute("/company/transactions")({
  loader: async ({ context: { client, stores } }) => {
    const defaultPagination = {
      offset: Timestamp.fromDate(new Date()),
      limit: Timestamp.fromDate(startOfDay(subDays(new Date(), 3))),
    };

    if (stores.length > 5) {
      defaultPagination.offset = Timestamp.fromDate(
        startOfDay(startOfDay(subDays(new Date(), 1))),
      );
    }

    if (stores.length > 50) {
      defaultPagination.limit = Timestamp.fromDate(
        startOfDay(subHours(new Date(), 3)),
      );
    }

    return { client, defaultPagination };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { client, defaultPagination } = Route.useLoaderData();
  const [transactions, setTransactions] = useState<TransactionGroup[]>([]);
  const [pagination, setPagination] = useState<TimePaginationResponse | null>(
    null,
  );
  const [offset, setOffset] = useState<Timestamp | null>(
    defaultPagination.offset,
  );
  const [limit, setLimit] = useState<Timestamp | null>(defaultPagination.limit);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  useEffect(() => {
    if (!offset || !limit) return;

    client.getTransactions({ pagination: { offset, limit } }).then((d) => {
      setTransactions(d.transactionGroups);
      setPagination(d.pagination ?? null);
    });
  }, [client, offset, limit]);

  const handleLoadMore = useCallback(() => {
    if (!pagination) return;
    client
      .getTransactions({ pagination: { offset: pagination.nextOffset } })
      .then((d) => {
        setTransactions([...transactions, ...d.transactionGroups]);
        setPagination(d.pagination ?? null);
      });
  }, [client, pagination, transactions]);

  const transactionCount = useMemo(() => {
    return transactions.length;
  }, [transactions]);

  const paymentAmount = useMemo(() => {
    return transactions.reduce((acc, group) => {
      return (
        acc +
        group.transactionDetails.reduce(
          (acc, detail) => acc + Number(detail.transaction?.amount ?? 0),
          0,
        )
      );
    }, 0);
  }, [transactions]);

  const handleDateSubmit = useCallback((range: Range) => {
    setIsOpenDatePicker(false);
    if (!range.startDate || !range.endDate) return;
    setOffset(Timestamp.fromDate(range.startDate));
    setLimit(Timestamp.fromDate(range.endDate));
  }, []);

  const handleClickRefund = useCallback(
    async (transactionGroupId: string) => {
      try {
        await client.refund({ transactionGroupId, reason: "返金" });
        toast.success("取引の返金に成功しました");
      } catch (e) {
        toast.error("取引の返金に失敗しました");
      }
    },
    [client],
  );

  return (
    <div
      className={css({
        py: "24px",
        pb: "100px",
        h: "100%",
        px: { base: "20px", md: "40px" },
        maxWidth: { base: "100vw", md: "100%" },
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        取引履歴
      </h1>

      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "6px",
          mb: "12px",
          width: "200px",
        })}
      >
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <div>ポイント利用件数</div>
          <div>{transactionCount}件</div>
        </div>
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <div>ポイント利用額</div>
          <div>{-paymentAmount}pt</div>
        </div>
      </div>
      <div
        className={css({
          overflowX: {
            base: "auto",
            md: "hidden",
          },
        })}
      >
        <div
          className={css({
            mt: "18px",
          })}
        >
          <div className={css({ position: "relative" })}>
            <button
              type="button"
              className={css({
                bg: "surface.accentPrimary",
                color: "text.inverse",
                border: "none",
                borderRadius: "8px",
                px: "12px",
                py: "4px",
                fontSize: "14px",
                cursor: "pointer",
              })}
              onClick={() => setIsOpenDatePicker(true)}
            >
              日時：2024/12/03 00:00 ~ 2024/12/05 15:17
            </button>

            {isOpenDatePicker && (
              <div
                className={css({
                  position: "absolute",
                  top: "30px",
                  right: "0",
                  left: 0,
                  bg: "white",
                  zIndex: "10",
                  borderRadius: "8px",
                  border: "1px solid",
                  borderColor: "border.secondary",
                  shadow: "md",
                  p: "12px",
                  width: "fit-content",
                })}
              >
                <DatePicker
                  onSubmit={handleDateSubmit}
                  onCancel={() => setIsOpenDatePicker(false)}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={css({
            mt: "12px",
            bg: "white",
            borderRadius: "10px",
            overflow: "hidden",
            border: "1px solid",
            borderColor: "border.secondary",
            fontSize: "12px",
            minWidth: "800px",
          })}
        >
          <table
            className={css({
              width: "100%",
              borderCollapse: "collapse",
            })}
          >
            <thead>
              <tr
                className={css({
                  borderBottom: "1px solid",
                  borderColor: "border.secondary",
                  bg: "white",
                })}
              >
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  日時
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  店舗名
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  支払ユーザーID
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  性別
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  年齢
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "right",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  ポイント
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "left",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  タグ
                </th>
                <th
                  className={css({
                    py: "12px",
                    px: "16px",
                    textAlign: "center",
                    fontWeight: "normal",
                    color: "text.secondary",
                  })}
                >
                  操作
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((group) =>
                group.transactionDetails.map((detail) => (
                  <tr
                    key={detail.transaction?.id}
                    className={css({
                      borderBottom: "1px solid",
                      borderColor: "border.secondary",
                      _last: {
                        borderBottom: "none",
                      },
                    })}
                  >
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      {detail.transaction?.createdAt?.toDate().toLocaleString()}
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      {detail.store?.name}
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      <Id id={detail.transaction?.userId ?? ""} />
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      {genderText(
                        detail.user?.gender ?? User_Gender.UNSPECIFIED,
                      )}
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      {Number(detail.user?.ageGroup)}代
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "right",
                      })}
                    >
                      {detail.transaction?.amount?.toString()}pt
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                      })}
                    >
                      レジ1
                    </td>
                    <td
                      className={css({
                        py: "12px",
                        px: "16px",
                        textAlign: "center",
                      })}
                    >
                      <button
                        type="button"
                        onClick={() => handleClickRefund(group.id)}
                        className={css({
                          color: "surface.accentPrimary",
                          border: "1px solid",
                          borderColor: "surface.accentPrimary",
                          borderRadius: "4px",
                          px: "12px",
                          py: "4px",
                          fontSize: "14px",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          minWidth: "100px",
                        })}
                      >
                        取消
                      </button>
                    </td>
                  </tr>
                )),
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={css({ textAlign: "center", mt: "20px" })}>
        {pagination?.nextOffset && (
          <button
            type="button"
            className={css({
              color: "text.secondary",
              fontSize: "14px",
              cursor: "pointer",
              bg: "white",
              border: "1px solid",
              borderColor: "border.secondary",
              borderRadius: "4px",
              px: "12px",
              py: "4px",
            })}
            onClick={handleLoadMore}
          >
            ↓ もっと見る
          </button>
        )}
      </div>
    </div>
  );
}
