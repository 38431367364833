export function isMockEnv() {
  const backendMode = import.meta.env.VITE_BACKEND_MODE;
  if (backendMode === "MOCK") {
    return true;
  }

  const { hostname } = location;
  const [tenantName] = hostname.split(".");
  return tenantName.endsWith("-mock");
}

export function getTenantSlug() {
  const { hostname } = location;

  const [tenantName, appName] = hostname.split(".");

  if (import.meta.env.VITE_TENANT_SLUG) {
    return import.meta.env.VITE_TENANT_SLUG;
  }

  // モック環境の場合はホスト名が demo-mock のようになるため
  const [tenant] = tenantName.split("-");

  if (appName === "console") {
    return tenant;
  }

  return "demo";
}
