import { createFileRoute } from "@tanstack/react-router";
import { css } from "../../../styled-system/css";

export const Route = createFileRoute("/company/redemptions")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div
      className={css({
        px: "40px",
        py: "24px",
        pb: "100px",
        h: "100%",
        overflow: "auto",
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        振込履歴
      </h1>
      <div
        className={css({
          display: "flex",
          flexDir: "column",
          gap: "6px",
          mb: "12px",
          width: "200px",
        })}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: "2",
          })}
        >
          <div>振込額合計</div>
          <div className={css({ fontSize: "xl", fontWeight: "bold" })}>0円</div>
        </div>
      </div>

      <div
        className={css({
          mt: "12px",
          bg: "white",
          borderRadius: "10px",
          overflow: "hidden",
          border: "1px solid",
          borderColor: "border.secondary",
          fontSize: "12px",
        })}
      >
        <table
          className={css({
            width: "100%",
            borderCollapse: "collapse",
          })}
        >
          <thead>
            <tr
              className={css({
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                bg: "white",
              })}
            >
              <th
                className={css({
                  py: "12px",
                  px: "16px",
                  textAlign: "left",
                  fontWeight: "normal",
                  color: "text.secondary",
                })}
              >
                振込処理開始日
              </th>
              <th
                className={css({
                  py: "12px",
                  px: "16px",
                  textAlign: "left",
                  fontWeight: "normal",
                  color: "text.secondary",
                })}
              >
                金額
              </th>
              <th
                className={css({
                  py: "12px",
                  px: "16px",
                  textAlign: "left",
                  fontWeight: "normal",
                  color: "text.secondary",
                })}
              >
                店舗名
              </th>
              <th
                className={css({
                  py: "12px",
                  px: "16px",
                  textAlign: "left",
                  fontWeight: "normal",
                  color: "text.secondary",
                })}
              >
                振込ID
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
}
