import { createFileRoute } from "@tanstack/react-router";
import { css } from "../../../styled-system/css";

export const Route = createFileRoute("/company/contact")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div
      className={css({
        h: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: { base: "20px", md: "40px" },
      })}
    >
      <section
        className={css({
          bg: "white",
          display: "flex",
          flexDirection: "column",
          gap: "4",
          p: "8",
          textAlign: "center",
          rounded: "lg",
          shadow: "sm",
        })}
      >
        <h2
          className={css({
            fontSize: "xl",
            fontWeight: "bold",
          })}
        >
          お電話にてご相談・お問い合わせください
        </h2>
        <p
          className={css({
            fontSize: "xl",
            fontWeight: "bold",
          })}
        >
          022-797-9582
        </p>
        <p>受付時間: 祝日と年末年始を除く10時から17時まで</p>
      </section>
    </div>
  );
}
