import { createFileRoute } from "@tanstack/react-router";
import { type ChangeEventHandler, useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import { css } from "../../../styled-system/css";

export const Route = createFileRoute("/company/dashboard")({
  loader: async ({ context: { client, company } }) => {
    return { client, company };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { client, company } = Route.useLoaderData();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<{
    image: Uint8Array;
    mimeType: string;
  } | null>(null);

  const onFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const file = event.target.files?.[0];

      if (!file) return;

      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target?.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);

        if (uint8Array.length === 0) {
          console.warn("The file is empty or could not be read.");
          toast.error("ファイルが空です。");
          return;
        }

        setImage({
          image: uint8Array,
          mimeType: file.type,
        });
      };

      reader.onerror = (error) => {
        console.error("File reading error:", error);
        toast.error("ファイルの読み込みに失敗しました。");
      };

      reader.readAsArrayBuffer(file);
    },
    [],
  );

  const onClickUpdateIcon = useCallback(async () => {
    if (!image) return;
    await client.updateMyCompanyImage({
      image,
    });
    setImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    toast.success("事業者アイコンを更新しました。");
    location.reload();
  }, [client, image]);

  return (
    <div
      className={css({
        p: { base: "20px", md: "40px" },
        h: "100%",
        maxWidth: "100%",
        overflowX: "hidden",
      })}
    >
      <h1
        className={css({
          fontSize: "18px",
          fontWeight: "bold",
          mb: "20px",
        })}
      >
        登録情報
      </h1>
      <div
        className={css({
          display: "flex",
          gap: { base: "16px", md: "20px" },
          flexWrap: "wrap",
        })}
      >
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            基本情報
          </h3>
          <ul
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            })}
          >
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                事業者名
              </span>
              <span>{company.name}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                登録代表者名
              </span>
              <span>{company.ownerName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                登録住所
              </span>
              <span>{company.address}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                電話番号
              </span>
              <span>{company.contact}</span>
            </li>
          </ul>
        </section>
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            登録銀行口座
          </h3>
          <ul
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            })}
          >
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                銀行名
              </span>
              <span>{company.bankName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                支店名
              </span>
              <span>{company.bankBranchName}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                口座種別
              </span>
              <span>{company.bankAccountType}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "border.secondary",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                口座番号
              </span>
              <span>{company.bankAccountNumber}</span>
            </li>
            <li
              className={css({
                display: "flex",
                justifyContent: "space-between",
                pb: "8px",
              })}
            >
              <span
                className={css({
                  color: "text.secondary",
                })}
              >
                口座名義
              </span>
              <span>{company.bankAccountHolderName}</span>
            </li>
          </ul>
        </section>
        <section
          className={css({
            bg: "white",
            p: { base: "16px", md: "20px" },
            borderRadius: "10px",
            width: { base: "100%", md: "calc(50% - 10px)" },
          })}
        >
          <h3
            className={css({
              fontSize: "16px",
              fontWeight: "bold",
              mb: "10px",
            })}
          >
            事業者アイコン
          </h3>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <img
              src={company.iconUrl}
              alt="事業者アイコン"
              className={css({
                maxW: "150px",
                maxH: "150px",
              })}
            />
          </div>

          <div
            className={css({
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            })}
          >
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              className={css({
                display: "block",
                mt: "10px",
                mx: "auto",
                fontSize: "14px",
                _file: {
                  bg: "background.background",
                  px: "10px",
                  py: "4px",
                  borderRadius: "5px",
                  mr: "10px",
                },
              })}
              onChange={onFileChange}
            />
            <button
              type="button"
              disabled={!image}
              onClick={onClickUpdateIcon}
              className={css({
                bg: "surface.accentPrimary",
                color: "white",
                borderRadius: "5px",
                px: "40px",
                fontSize: "14px",
                py: "5px",
                display: "block",
                mx: "auto",
                _disabled: {
                  bg: "surface.disable",
                },
              })}
            >
              事業者アイコンに設定する
            </button>
          </div>
        </section>
      </div>
    </div>
  );
}
