import { css } from "../../styled-system/css";

type Props = {
  id: string;
};

export default function Id({ id }: Props) {
  return (
    <div
      className={css({
        width: "5rem",
        position: "relative",
        height: "1.5rem",
      })}
    >
      <div
        className={css({
          position: "absolute",
          top: 0,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "full",
          bg: "gray.200",
          borderRadius: "full",
          fontSize: "xs",
          px: 3,
          py: 1,
          fontWeight: "semibold",
          _hover: {
            width: "auto",
          },
        })}
      >
        {id}
      </div>
    </div>
  );
}
