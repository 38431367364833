/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as CompanyImport } from './routes/company'
import { Route as AdminImport } from './routes/admin'
import { Route as IndexImport } from './routes/index'
import { Route as LoginCompanyImport } from './routes/login/company'
import { Route as CompanyTransactionsImport } from './routes/company/transactions'
import { Route as CompanyRedemptionsImport } from './routes/company/redemptions'
import { Route as CompanyDashboardImport } from './routes/company/dashboard'
import { Route as CompanyContactImport } from './routes/company/contact'
import { Route as CompanyStoresIndexImport } from './routes/company/stores/index'
import { Route as CompanyStoresStoreIdQrcodeImport } from './routes/company/stores/$storeId.qrcode'

// Create/Update Routes

const CompanyRoute = CompanyImport.update({
  id: '/company',
  path: '/company',
  getParentRoute: () => rootRoute,
} as any)

const AdminRoute = AdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const LoginCompanyRoute = LoginCompanyImport.update({
  id: '/login/company',
  path: '/login/company',
  getParentRoute: () => rootRoute,
} as any)

const CompanyTransactionsRoute = CompanyTransactionsImport.update({
  id: '/transactions',
  path: '/transactions',
  getParentRoute: () => CompanyRoute,
} as any)

const CompanyRedemptionsRoute = CompanyRedemptionsImport.update({
  id: '/redemptions',
  path: '/redemptions',
  getParentRoute: () => CompanyRoute,
} as any)

const CompanyDashboardRoute = CompanyDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => CompanyRoute,
} as any)

const CompanyContactRoute = CompanyContactImport.update({
  id: '/contact',
  path: '/contact',
  getParentRoute: () => CompanyRoute,
} as any)

const CompanyStoresIndexRoute = CompanyStoresIndexImport.update({
  id: '/stores/',
  path: '/stores/',
  getParentRoute: () => CompanyRoute,
} as any)

const CompanyStoresStoreIdQrcodeRoute = CompanyStoresStoreIdQrcodeImport.update(
  {
    id: '/stores/$storeId/qrcode',
    path: '/stores/$storeId/qrcode',
    getParentRoute: () => CompanyRoute,
  } as any,
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/admin': {
      id: '/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AdminImport
      parentRoute: typeof rootRoute
    }
    '/company': {
      id: '/company'
      path: '/company'
      fullPath: '/company'
      preLoaderRoute: typeof CompanyImport
      parentRoute: typeof rootRoute
    }
    '/company/contact': {
      id: '/company/contact'
      path: '/contact'
      fullPath: '/company/contact'
      preLoaderRoute: typeof CompanyContactImport
      parentRoute: typeof CompanyImport
    }
    '/company/dashboard': {
      id: '/company/dashboard'
      path: '/dashboard'
      fullPath: '/company/dashboard'
      preLoaderRoute: typeof CompanyDashboardImport
      parentRoute: typeof CompanyImport
    }
    '/company/redemptions': {
      id: '/company/redemptions'
      path: '/redemptions'
      fullPath: '/company/redemptions'
      preLoaderRoute: typeof CompanyRedemptionsImport
      parentRoute: typeof CompanyImport
    }
    '/company/transactions': {
      id: '/company/transactions'
      path: '/transactions'
      fullPath: '/company/transactions'
      preLoaderRoute: typeof CompanyTransactionsImport
      parentRoute: typeof CompanyImport
    }
    '/login/company': {
      id: '/login/company'
      path: '/login/company'
      fullPath: '/login/company'
      preLoaderRoute: typeof LoginCompanyImport
      parentRoute: typeof rootRoute
    }
    '/company/stores/': {
      id: '/company/stores/'
      path: '/stores'
      fullPath: '/company/stores'
      preLoaderRoute: typeof CompanyStoresIndexImport
      parentRoute: typeof CompanyImport
    }
    '/company/stores/$storeId/qrcode': {
      id: '/company/stores/$storeId/qrcode'
      path: '/stores/$storeId/qrcode'
      fullPath: '/company/stores/$storeId/qrcode'
      preLoaderRoute: typeof CompanyStoresStoreIdQrcodeImport
      parentRoute: typeof CompanyImport
    }
  }
}

// Create and export the route tree

interface CompanyRouteChildren {
  CompanyContactRoute: typeof CompanyContactRoute
  CompanyDashboardRoute: typeof CompanyDashboardRoute
  CompanyRedemptionsRoute: typeof CompanyRedemptionsRoute
  CompanyTransactionsRoute: typeof CompanyTransactionsRoute
  CompanyStoresIndexRoute: typeof CompanyStoresIndexRoute
  CompanyStoresStoreIdQrcodeRoute: typeof CompanyStoresStoreIdQrcodeRoute
}

const CompanyRouteChildren: CompanyRouteChildren = {
  CompanyContactRoute: CompanyContactRoute,
  CompanyDashboardRoute: CompanyDashboardRoute,
  CompanyRedemptionsRoute: CompanyRedemptionsRoute,
  CompanyTransactionsRoute: CompanyTransactionsRoute,
  CompanyStoresIndexRoute: CompanyStoresIndexRoute,
  CompanyStoresStoreIdQrcodeRoute: CompanyStoresStoreIdQrcodeRoute,
}

const CompanyRouteWithChildren =
  CompanyRoute._addFileChildren(CompanyRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/admin': typeof AdminRoute
  '/company': typeof CompanyRouteWithChildren
  '/company/contact': typeof CompanyContactRoute
  '/company/dashboard': typeof CompanyDashboardRoute
  '/company/redemptions': typeof CompanyRedemptionsRoute
  '/company/transactions': typeof CompanyTransactionsRoute
  '/login/company': typeof LoginCompanyRoute
  '/company/stores': typeof CompanyStoresIndexRoute
  '/company/stores/$storeId/qrcode': typeof CompanyStoresStoreIdQrcodeRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/admin': typeof AdminRoute
  '/company': typeof CompanyRouteWithChildren
  '/company/contact': typeof CompanyContactRoute
  '/company/dashboard': typeof CompanyDashboardRoute
  '/company/redemptions': typeof CompanyRedemptionsRoute
  '/company/transactions': typeof CompanyTransactionsRoute
  '/login/company': typeof LoginCompanyRoute
  '/company/stores': typeof CompanyStoresIndexRoute
  '/company/stores/$storeId/qrcode': typeof CompanyStoresStoreIdQrcodeRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/admin': typeof AdminRoute
  '/company': typeof CompanyRouteWithChildren
  '/company/contact': typeof CompanyContactRoute
  '/company/dashboard': typeof CompanyDashboardRoute
  '/company/redemptions': typeof CompanyRedemptionsRoute
  '/company/transactions': typeof CompanyTransactionsRoute
  '/login/company': typeof LoginCompanyRoute
  '/company/stores/': typeof CompanyStoresIndexRoute
  '/company/stores/$storeId/qrcode': typeof CompanyStoresStoreIdQrcodeRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/admin'
    | '/company'
    | '/company/contact'
    | '/company/dashboard'
    | '/company/redemptions'
    | '/company/transactions'
    | '/login/company'
    | '/company/stores'
    | '/company/stores/$storeId/qrcode'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/admin'
    | '/company'
    | '/company/contact'
    | '/company/dashboard'
    | '/company/redemptions'
    | '/company/transactions'
    | '/login/company'
    | '/company/stores'
    | '/company/stores/$storeId/qrcode'
  id:
    | '__root__'
    | '/'
    | '/admin'
    | '/company'
    | '/company/contact'
    | '/company/dashboard'
    | '/company/redemptions'
    | '/company/transactions'
    | '/login/company'
    | '/company/stores/'
    | '/company/stores/$storeId/qrcode'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AdminRoute: typeof AdminRoute
  CompanyRoute: typeof CompanyRouteWithChildren
  LoginCompanyRoute: typeof LoginCompanyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminRoute: AdminRoute,
  CompanyRoute: CompanyRouteWithChildren,
  LoginCompanyRoute: LoginCompanyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/admin",
        "/company",
        "/login/company"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/admin": {
      "filePath": "admin.tsx"
    },
    "/company": {
      "filePath": "company.tsx",
      "children": [
        "/company/contact",
        "/company/dashboard",
        "/company/redemptions",
        "/company/transactions",
        "/company/stores/",
        "/company/stores/$storeId/qrcode"
      ]
    },
    "/company/contact": {
      "filePath": "company/contact.tsx",
      "parent": "/company"
    },
    "/company/dashboard": {
      "filePath": "company/dashboard.tsx",
      "parent": "/company"
    },
    "/company/redemptions": {
      "filePath": "company/redemptions.tsx",
      "parent": "/company"
    },
    "/company/transactions": {
      "filePath": "company/transactions.tsx",
      "parent": "/company"
    },
    "/login/company": {
      "filePath": "login/company.tsx"
    },
    "/company/stores/": {
      "filePath": "company/stores/index.tsx",
      "parent": "/company"
    },
    "/company/stores/$storeId/qrcode": {
      "filePath": "company/stores/$storeId.qrcode.tsx",
      "parent": "/company"
    }
  }
}
ROUTE_MANIFEST_END */
